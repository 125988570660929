const apiBaseURL = 'https://back-acciona-flora.cecoc.es';

export const appConfig: any = {

  apiConfig: {
    baseURL: apiBaseURL,
  }

};

const KEYCLOAK_AUTH_URL = 'https://auth-pre.accionaconstruccion.com/realms/EXPERTIS/protocol/openid-connect/auth';
const KEYCLOAK_TOKEN_URL = 'https://auth-pre.accionaconstruccion.com/realms/EXPERTIS/protocol/openid-connect/token';
const KEYCLOAK_CLIENT_ID = 'con_cecoc_flora_frontend';
const KEYCLOAK_AUTH_REDIRECT_URI = '/login';
const KEYCLOAK_TOKEN_REDIRECT_URI = '/login';

export { KEYCLOAK_AUTH_URL, KEYCLOAK_TOKEN_URL, KEYCLOAK_CLIENT_ID, KEYCLOAK_AUTH_REDIRECT_URI, KEYCLOAK_TOKEN_REDIRECT_URI };