import React, { useState, useContext, useEffect } from 'react';
import Input from '../../components/Input';
import Button from '../../components/Button';
import Checkbox from '../../components/Checkbox';

import logo from '../../assets/logos/logo-gris.svg';
import powered from '../../assets/icons/powered.svg';
import '../../assets/scss/pages/_login.scss';
import { Link } from "react-router-dom";
import { useAuth } from '../../context/AuthContext';
import { useNavigate } from 'react-router-dom';
import { ErrorContext, ErrorContextType } from '../../context/ErrorContext';
import axios, { AxiosError } from 'axios';
import {jwtDecode} from "jwt-decode";
import { useLocation } from 'react-router-dom';

// Keycloak
import { KEYCLOAK_AUTH_URL, KEYCLOAK_TOKEN_URL, KEYCLOAK_CLIENT_ID, KEYCLOAK_AUTH_REDIRECT_URI, KEYCLOAK_TOKEN_REDIRECT_URI } from '../../config/app';

function LoginPage() {
  const navigate = useNavigate();
  const { login, logout, getUserByCode } = useAuth();
  /* const [loginData, setLoginData] = useState({
    email: '',
    password: '',
    remember: false,
  });

  const handleInputChange = (field: string, value: string) => {
    setLoginData({ ...loginData, [field]: value });
  };

  const { showErrorModal } = useContext<ErrorContextType>(ErrorContext);

  const handleLogin = async () => {
    try {
      await logout(navigate);
      await login(loginData, navigate);
      navigate('/home');
    } catch (error) {
      if (axios.isAxiosError(error)) {
        showErrorModal(
          'Error en login',
          'Hubo un error durante el inicio de sesión: ' + error?.response?.data?.message,
          'error');
        console.error('Error de inicio de sesión:', error);
      }
    }
  }; */

  const location = useLocation();
  const { showErrorModal } = useContext<ErrorContextType>(ErrorContext);

  const handleKeycloakLogin = () => {
    const keycloakLoginUrl = `${KEYCLOAK_AUTH_URL}?client_id=${KEYCLOAK_CLIENT_ID}&redirect_uri=${encodeURIComponent(window.location.origin + KEYCLOAK_AUTH_REDIRECT_URI)}&response_type=code&scope=openid%20profile%20email`;
    window.location.href = keycloakLoginUrl;
  };

  interface JwtPayload {
    email: string;
  }

  useEffect(() => {
    const fetchData = async () => {
      try {
        const queryParams = new URLSearchParams(location.search);
        const code = queryParams.get('code');

        if (code) {
          const data = new URLSearchParams();
          data.append('grant_type', 'authorization_code');
          data.append('client_id', KEYCLOAK_CLIENT_ID);
          data.append('code', code);
          data.append('redirect_uri', window.location.origin + KEYCLOAK_AUTH_REDIRECT_URI);

          const response = await axios.post(
            KEYCLOAK_TOKEN_URL,
            data.toString(),
            {
              headers: {
                'Content-Type': 'application/x-www-form-urlencoded'
              }
            }
          );

          const accessToken = response.data.access_token;
          const tokenPayload = jwtDecode<any>(accessToken);

          if (tokenPayload && tokenPayload.email) {
            try {
              const email = tokenPayload.email;
              console.log('Email del usuario:', email);
              await getUserByCode(email);
              navigate('/home');
            } catch(error: any) {
              showErrorModal('El usuario no tiene acceso', error.message, 'error');
            }
          } else {
            console.error('El token JWT no contiene la propiedad de email esperada.');
            showErrorModal(
              'Error en login',
              'No se ha recibido la respuesta esperada en el login',
              'error');
          }
        }
      } catch (error) {
        console.error('Error al obtener el token:', error);
      }
    };

    fetchData();
  }, [location.search]);

  return (
    <div className="login-page">
      <div>
        <div className="login-div">
          <div className='centerText'><img src={logo} alt="Home"/></div>
          {/* <div className='centerText'><h1>Ingresar a mi cuenta</h1></div>
          <Input label="Email"
                 value={loginData.email}
                 placeholder="Introducir email"
                 onChange={(value) => handleInputChange('email', value)}
          />
          <Input label="Contraseña"
                 value={loginData.password}
                 placeholder="••••••••"
                 type="password"
                 onChange={(value) => handleInputChange('password', value)}
          />

          <div className="links-container">
            <Checkbox
              checked={loginData.remember}
              onChecked={ checked => {
                setLoginData({ ...loginData, remember: checked });
              }}
              label="Recordarme"
            />

            <Link className="link" to="/recuperar-contrasena">
              Olvidé mi contraseña
            </Link>

          </div>

          <Button color="primary" onClick={handleLogin}>Ingresar</Button> */}
          <br></br><br></br><br></br>
          <Button color="primary" onClick={handleKeycloakLogin}>Ingresar con Keycloak</Button>
          <div className="powered"><img src={powered} /></div>
        </div>
      </div>


    </div>
  );
}

export default LoginPage;