import { createContext, useState, useContext, useEffect } from 'react';
import { postData, getData, deleteData } from "../API/index";

const AuthContext = createContext();

// Función para guardar el usuario
const saveUser = async (setUser, userData) => {
    try {
        await setUser(userData);
        localStorage.setItem('user', JSON.stringify(userData));
        console.log('Usuario guardado correctamente:', userData);
    } catch (error) {
        console.error('Error al guardar el usuario:', error);
        throw error;
    }
};

export const AuthProvider = ({ children }) => {
    const [token, setToken] = useState('');
    const [user, setUser] = useState('');

    const login = async (loginData, navigate) => {
        try {
            // Token
            const response = await postData('/login', loginData);
            const accessToken = response.data.token;
            await setToken(accessToken);
            localStorage.setItem('accessToken', accessToken);

            // Usuario
            const userResponse = await getData('/user', navigate, accessToken);
            const userData = userResponse.data;
            await saveUser(setUser, userData);
        } catch (error) {
            console.error('Error en el inicio de sesión:', error);
            throw error;
        }
    };

    // Función para realizar el logout
    const logout = async (navigate) => {
        try {
            await deleteData('/logout', navigate, token);
        } catch (error) {
            console.error('Error en logout:', error);
        }

        localStorage.removeItem('accessToken');
        localStorage.removeItem('user');
        setToken('');
        setUser('');
        navigate('/login');
    };

    const isAuthenticated = () => {
      return localStorage.getItem("accessToken") ? true : false;
    };
    
    const getUserByCode = async (code) => {
        try {
            // Get usuario
            const userResponse = await getData(`/user-code?code=${encodeURIComponent(code)}`);

            // Token
            const accessToken = userResponse.data.token;
            await setToken(accessToken);
            localStorage.setItem('accessToken', accessToken);
            console.log(userResponse);

            // Usuario
            const userData = userResponse.data;
            if(userResponse.data.success) {
                await saveUser(setUser, userData);
            } else {
                throw new Error(userResponse.data.message);
            }
        } catch (error) {
            throw error;
        }
    };

    useEffect(() => {
        const storedToken = localStorage.getItem('accessToken');
        if (storedToken) {
            setToken(storedToken);
        }

        const storedUser = localStorage.getItem('user');
        if (storedUser) {
            setUser(JSON.parse(storedUser));
        }
    }, []);

    return (
        <AuthContext.Provider value={{ token, setToken, user, setUser, login, logout, saveUser, getUserByCode, isAuthenticated }}>
            {children}
        </AuthContext.Provider>
    );
};

export const useAuth = () => {
    return useContext(AuthContext);
};

export { saveUser };